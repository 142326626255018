import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { DirectDebitService } from 'src/app/services/direct-debit/direct-debit.service';
import { mobiscroll, MbscDatetimeOptions, MbscFormOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};
const now = new Date();
let week = [ new Date() ,new Date()];


@Component({
  selector: 'app-direc-debit-history',
  templateUrl: './direc-debit-history.component.html',
  styleUrls: ['./direc-debit-history.component.css'],
  providers: [DatePipe]
})
export class DirecDebitHistoryComponent implements OnInit {
  range: Array < Date > = week;
  d1: any;
  d2: any;
  bank: any;
  type: any;
  loading = false;
  historic_list: any = [];

  types: any = [{
    "id": 1,
    "name": "Diario",
  },{
    "id": 2,
    "name": "Masivo",
  },{
    "id": 3,
    "name": "Morosos",
  }];

  statuses: any = [{
    "id": 'INITIAL',
    "name": "Sin comenzar",
  },{
    "id": "GENERATED",
    "name": "El archivo de carga másiva fue generado",
  },{
    "id": "DOWNLOADED",
    "name": "El archivo de carga másiva ha sido descargado",
  },{
    "id": "UPLOADED",
    "name": "El archivo ya fue cargado en el banco y se encuentra en espera de respuesta",
  },{
    "id": "PROCESING",
    "name": "Se recibió la respuesta del banco y se encuentra procesando",
  }
  ,{
    "id": "FINISHED",
    "name": "El proceso se finalizó y ya se realizó la conciliación",
  }];

  banks: any = [];

  dateSetting: MbscDatetimeOptions = {
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light',
    max:now,
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  }
  
  };

  form: FormGroup | any = this.formBuilder.group(
    {
      bank: ['1'],
      type: ['']
    }
  );
  
  constructor(    
    private formBuilder: FormBuilder,
    private userService: UserService,
    private datePipe: DatePipe,
    private directDebitService: DirectDebitService
  ) { }

  birthdaySet = () => {

  }

  

  async ngOnInit() {
    this.banks = await this.getBanks();
    this.banks = this.banks.res;
    this.setDefault();
    this.updateCurrentDirectDebit();
  }

  setDefault = () => {
    if(this.banks.length===1) {
      this.form.get('bank').setValue(this.banks[0].code);
      this.form.get('bank').patchValue(this.banks[0].code);
    }
    if(this.types.length>=1) {
      this.form.get('bank').setValue(this.banks[0].code);
      this.form.get('type').setValue(this.types[0].id);
      this.form.get('type').patchValue(this.types[0].id);
    }
  }

  getBanks = async () => {
    return await this.userService.getBanksWithDirectDebitEnabled({});
  }


  updateCurrentDirectDebit = async () => {
    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
    this.bank = this.form.get('bank').value;
    this.type = this.form.get('type').value;

    this.getHistoric();
  }

  getHistoric = async () => {
    let body = {
      bank: this.bank,
      type: this.type,
      from: this.d1,
      to: this.d2
    };
    this.historic_list = await this.directDebitService.historic(body);
    if(this.historic_list) {
      this.historic_list = this.historic_list.resp;
    }
  }

  getDescriptionStatus = (value: string) => {
    let response = this.statuses.find((row: any)=> {
      return row.id===value;
    });
    return response.name;
  }

  downloadFile = async (url:any, extension: string, name: string) => {
    let res = await this.directDebitService.downloadImage(url, extension);
    if(res) {
      saveAs(res, `${name}.${extension}`);
    }
  }

}
