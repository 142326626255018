


import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-clients-stadistic-auth',
  templateUrl: './clients-stadistic-auth.component.html',
  styleUrls: ['./clients-stadistic-auth.component.css']
})
export class ClientsStadisticAuthComponent implements OnInit {


  loading:any;
  shop:any;
  stadistic:any;
  shops:any;
  shops_obj:any;
  stepsObj: any ={};
  stepObjData: any ={};

  steps: any ={};
  reloadStadistic:any;
  reloadStadisticLoading:any;
  client_stadistic:any;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    public socketService: SocketioService
  ) { }



  ngOnInit(): void {
    

    if(localStorage.getItem('client-stadistic')){

      let jsta:any = localStorage.getItem('client-stadistic');

      const client_stadistic = JSON.parse(jsta);

      this.stadistic = client_stadistic?.stadistic;

      this.client_stadistic = client_stadistic;


    }else{

        this.stadisticGet()


    }
     

  }


  stadisticGet = async (init= true) => {
    try {

      if(init){
        this.loading = true;

      }else{
        this.reloadStadisticLoading = true;

      }

      const resp = await this.getStadisticServer();
     this.stadistic = resp?.resp;

     const client_stadistic = {
      date:new Date(),
      stadistic:resp?.resp


     }
     this.client_stadistic = client_stadistic;

     const cadenaJSON = JSON.stringify(client_stadistic);

     localStorage.setItem('client-stadistic',cadenaJSON)


        
    } catch (error) {
      console.error(error);



    } 
    this.loading = false;
    this.reloadStadisticLoading = false;

   


  }



  getStadisticServer = async () => {
    try{
        return this._user.clientStadistic({});

      } catch (error) {
        console.error(error);
        
    
      }
  }


  
  
}
