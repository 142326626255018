<div class="mt-md-3 container-fluid">
  <div class="row mt-2">
    <div class="col-12 col-md-8 offset-md-2">
      <div class="row rmt-2">
        <div class="col-12 col-md-12">
          <div class="h4 text-dark mt-5 mb-2">
            <span
              [routerLink]="['/console/dashboard-direct-debit']"
              class="fz-16 fas fa-arrow-left me-2"
            >
            </span>

            <span class="text-primary">{{ "Historico Domiciliaciones" }}</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow-sm">
          <div class="col-12 text-end pt-2">
            <div class="col-12 col-md-12 mt-2">
              <input
                style="padding: 16px 9px; background: white"
                id="demo-external"
                (onSet)="updateCurrentDirectDebit()"
                [(ngModel)]="range"
                mbsc-range
                class="form-control"
                [mbsc-options]="dateSetting"
                #externalRange="mobiscroll"
              />
            </div>

            <form
              autocomplete="nope"
              novalidate
              [formGroup]="form"
              class="text-start"
            >
              <div class="row mt-2">
                <div class="form-group col-6 mt-4">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="bank"
                      formControlName="bank"
                      aria-label="Floating label select example"
                      (change)="updateCurrentDirectDebit()"
                    >
                      <option
                        *ngFor="let item of banks; let i = index"
                        [value]="item?.code"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="origin_account">Entidad Bancaria</label>
                  </div>
                </div>

                <div class="form-group col-6 mt-4">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="type"
                      formControlName="type"
                      aria-label="Floating label select example"
                      (change)="updateCurrentDirectDebit()"
                    >
                      <option
                        *ngFor="let item of types; let i = index"
                        [value]="item?.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="origin_account">Tipo</label>
                  </div>
                </div>
              </div>
            </form>
            <!-- <button type="button" class="btn-close" aria-label="Close" (click)=" view > 1 ? view = 1 : offcanvas.dismiss('Cross click'); "></button> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow-sm">
          <ng-container *ngIf="historic_list.length > 0">
            <ng-container *ngFor="let row of historic_list">

              <div class="cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow">
                <div class="text-dark pt-3 w-100 " >
                    <div class="pb-3 mb-0 small lh-sm  w-100">
                      <div class="d-flex justify-content-between">
                          <span> 
                            <span class="font-weight-bold">
                                {{ row.type==="1"?'Diario': type==="2"?'Masivo': type==="3"?'Morosos': '' }} <br>
                                {{ 'Lote:'+row.lote }}</span><br>
                                {{ row.date | date: 'dd/MM/yyyy' }}<br>
                                {{ getDescriptionStatus(row.status) }}
                            </span>
                            <div class="font-weight-bold fs-16 text-end pull-right w-100"> {{ row.total_amount }} Bs / BCV: {{ row.rate }}  </div>
                          <br>
                          
      
                      </div>
      
                      
                    </div>
                  </div>

                <!---->
                <div class="pt-2 font-weight-bold small" *ngIf="row.directDebitStatus.length>0">
                    Archivos:
                </div>
                <div class="small" *ngFor="let ele of row.directDebitStatus">
                    <div *ngIf="ele.status==='GENERATED'">
                      <a href="javascript:void()" (click)="downloadFile(ele.url, 'txt', 'domiciliacion_'+row.lote)" class="active-cursor" title="Descargar Archivo de CObro">Descargar</a> &nbsp; <span class="font-weight-bold">{{ele?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</span> &nbsp; Archivo de domiciliación 
                    </div>
                    <div *ngIf="ele.status==='PROCESING'">
                      <a href="javascript:void()" (click)="downloadFile(ele.url, 'txt', 'domiciliacion_respuesta_banco_'+row.lote)" class="active-cursor" title="Descargar Archivo de Domiciliación">Descargar</a> &nbsp; <span class="font-weight-bold">{{ele?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</span> &nbsp; Respuesta del Banco 
                    </div>
                    <div *ngIf="ele.status==='FINISHED'">
                      <ng-container *ngIf="ele?.url_failed"><a href="javascript:void()" (click)="downloadFile(ele.url_failed, 'xlsx', 'domiciliacion_resumen_'+row.lote)" class="active-cursor" title="Descargar Archivo de Domiciliación Corrida">Descargar</a> &nbsp; <span class="font-weight-bold">{{ele?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</span> &nbsp; Resumen de Domiciliación <span *ngIf="ele?.url_failed">(Última Corrida) </span> <br></ng-container>
                      <a href="javascript:void()" (click)="downloadFile(ele.url, 'xlsx', 'domiciliacion_resumen_general_'+row.lote)" class="active-cursor" title="Descargar Archivo de Domiciliación Corrida">Descargar</a> &nbsp;  <span class="font-weight-bold">{{ele?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</span> &nbsp; <span *ngIf="ele?.url">Resumen de Domiciliación </span>

                    </div>
                </div>

                <!-- <div class="border-bottom pt-4"></div> -->
              </div>
            </ng-container>
            
          </ng-container>

          <ng-container *ngIf="historic_list.length === 0">
            <div class="d-flex text-dark pt-3 ">
              <div class="pb-3 mb-0 small lh-sm cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow w-100">
                <div class="  text-center pt-3 pb-3">
                  SIN RESULTADOS
                </div>
               
              </div>
              
            </div>
            <br>
          </ng-container>


        </div>
      </div>
    </div>
  </div>
</div>
